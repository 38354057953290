import React from "react"
import "./team.scss"
import linkedin from "../../static/linkedin.png" 
const TeamComponent = props => {
  const { title, teamCards } = props.data
  return (
    <section className="section container team">
      <h1 className="section-title">{title}</h1>

      {teamCards.map((card, index) => {
        return (
          <div className={`team-member-card ${index % 2 !== 0 ? 'team-card-reverse' : ''}`} key={index}>
            <div className="member-image">
              <img src={card.image.file.url}></img>
            </div>
            <div className="content">

              <div className="content-title">
              <a target="_blank" href={card.url}>
                <div className="name">
                 <span className="text">{card.name}</span> <img src={linkedin}></img>
                </div>
                </a>

                <div className="position">
                  {card.jobDescription}
                </div>
              </div>
              <div className="content-text">
                {card.description.description}
              </div>
            </div>
          </div>
        )
      })}
    </section>
  )
}

export default TeamComponent
