import React from "react"
import "./banner.scss"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import {
  FormattedMessage,
  injectIntl,
  Link,
  useIntl,
} from "gatsby-plugin-react-intl";
import banner from "../../static/banner.png";


const BannerComponent = props => {
  const intl = useIntl()
  const { data } = props
  const { file, title: imageTitle } = data.image
  return (
    <section className="container banner">
      <div className="left">
        <div className="text">
          <div className="title">
            <h1>{data.title}</h1>
          </div>

          {/* <div className="paragraph">
            <p> {data.text.text}</p>
          </div> */}
        </div>
        <div className="button">
        <Link to="/contact" title="contact">
        <button className="button-main">{data.buttonText}</button>
        </Link>

        </div>
      </div>

      {/* <div className="right">
        <img src={file.url} alt={imageTitle}></img>
      </div> */}
    </section>
  )
}

export default BannerComponent
