import React from "react"
import { Carousel } from "../lib/carousel/carousel"
import "./projects.scss"
import {
  ANIMATION_DURATION,
  ANIMATION_OFFSET,
  ANIMATION_TYPE,
} from "../../constants"

const ProjectsComponent = props => {
  const { header, projects } = props.data
  return (
    <div id="projects" className="section container projects">
      <h1 className="section-title">{header}</h1>
      <Carousel items={projects}></Carousel>
    </div>
  )
}

export default ProjectsComponent
