import React from "react"
import { PostsCards } from "../lib/posts-cards/posts-cards"
import "./case-studies.scss"

export const CaseStudiesComponent = props => {
  const {title, posts} = props.data;
  return (
    <section className="section container case-studies">
      <div className="cases-title">
        <h1 style={{ textAlign: "left" }} className="section-title">
          {title}
        </h1>
      </div>

    <PostsCards posts={posts}></PostsCards>
    </section>
  )
}
