import React from "react"
import "./posts-cards.scss"
import { navigate } from "gatsby"

import {
  FormattedMessage,
  injectIntl,
  useIntl,
  Link,
} from "gatsby-plugin-react-intl"

export const PostsCards = props => {
  const intl = useIntl()

  const { posts } = props
  return (
    <div className="post-cards">
      {posts &&
        posts.length &&
        posts.map((post, index) => (
          <div
            onClick={() => {
              navigate(`/case-studies-and-stories/${post.slug}`)
            }}
            key={index}
            className="post-card"
          >
            <div className="post-image">
              <img src={post.image.file.url} alt={post.title}></img>
            </div>
            <div className="card-title">{post.title}</div>
            <div className="card-description">{post.description}</div>

            <div className="action-btn">
              <button className="button-sub">
                <FormattedMessage id="general.readMore" />  
              </button>
            </div>
          </div>
        ))}
    </div>
  )
}
