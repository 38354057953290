import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BannerComponent from "../components/banner/banner"
import OurServicesComponent from "../components/our-services/our-services"
import WhyWeComponent from "../components/why-we/why-we"
import ProjectsComponent from "../components/projects/projects"
import ContactComponent from "../components/contact/contact"
import { Helmet } from "react-helmet"
import { IntroIdeaComponent, KalAIComponent, OurProcessComponent } from "../components"
import { CaseStudiesComponent } from "../components/case-studies/case-studies"
import { ContactBannerComponent } from "../components/contact-banner/contact-banner"
import { BlogPostsStripComponent } from "../components/blog-posts-stripe/blog-posts-stripe"
import TeamComponent from "../components/team/team"
import WhitepaperBanner from "../components/whitepaper-link/whitepaper-link"

const IndexPage = ({ data, pageContext }) => {
  console.log(data.contentfulPage)
  const { banner, contact, projects, services, whyWe, challenge, processes, mainPosts, whoAreWe } = data.contentfulPage

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Software Development and AI solutions | KalBytes</title>
        <link rel="canonical" href="https://www.kalbytes.de" />
      </Helmet>
      <BannerComponent data={banner} />
      
      {/* <BlogPostsStripComponent data={news}></BlogPostsStripComponent> */}
      {/* <KalAIComponent></KalAIComponent> */}
      <OurServicesComponent data={services} />


      {/* <IntroIdeaComponent data={challenge}></IntroIdeaComponent> */}
      <OurProcessComponent data={processes}></OurProcessComponent>
      {/* <IntroIdeaComponent data={challenge}></IntroIdeaComponent> */}
      <TeamComponent data={whoAreWe}></TeamComponent>

      <WhitepaperBanner></WhitepaperBanner>


      <CaseStudiesComponent data={mainPosts}></CaseStudiesComponent>

      {/* <CaseStudiesComponent data={mainPosts}></CaseStudiesComponent> */}
      {/* <WhyWeComponent data={whyWe} />
      <ProjectsComponent data={projects} />
      <ContactComponent data={contact} /> */}
    </Layout>
  )
}

export const query = graphql`
  query PageQuery($id: String, $locale: String) {
    contentfulPage(
      contentful_id: { eq: $id }
      node_locale: { eq: $locale }
      services: {}
    ) {
      node_locale
      id
      banner {
        title
        subTitle
        buttonText
        image {
          file {
            url
          }
          title
        }
        text {
          text
        }
      }

      services {
        header
        cards {
          title
          icon {
            file {
              url
            }
            title
          }
          description {
            description
          }
        }
      }

      challenge {
        title
        innovationCards {
          title
          text {
            text
          }
          icon {
            file {
              url
            }
            title
          }
        }
      }

      processes {
        title
        ourProcesses {
          name
          action {
            action
          }
          effect {
            effect
          }
          icon {
            file {
              url
            }
            title
          }
        }
      }

      whoAreWe {
        title 
        teamCards {
          name
          jobDescription
          url
          description {
            description
          }
          image {
            file {
              url
            }
          }
        }
      }


      mainPosts {
        title
        posts {
          title
          description 
          slug
          image {
            file {
              url
            }
          }
        }
      }

      footer {
        socialIcons {
          href
          icon {
            file {
              url
            }
            title
          }
        }
      }
    }
  }
`

export default IndexPage
