import * as React from "react"
import whitepaperLink from '../../images/whitepaper-link.png';
import { Link } from 'gatsby';

const WhitepaperBanner = () => {
  return (
    <div className="whitepaper-banner">
      <Link to="/ai-white-paper">
        <img 
          src={whitepaperLink} 
          alt="Whitepaper Link Banner" 
          style={{
            display: 'block',
            maxWidth: '1200px',
            width: '100%',
            height: 'auto',
            margin: '0 auto',
            cursor: 'pointer'
          }}
        />
      </Link>
    </div>
  );
};

export default WhitepaperBanner;