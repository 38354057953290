import React, { useState } from "react"

import "./our-process.scss"

export const OurProcessComponent = props => {
  const { title, ourProcesses} = props.data

  const [activeCircle, setActiveCircle] = useState(ourProcesses[0]);
  const [activeCircleIndex, setActiveCircleIndex] = useState(0);

  const onCircleClick = (index) => {
    setActiveCircleIndex(index);
    setActiveCircle(ourProcesses[index])
  }

  return (
    <section className="section container our-process">
      <h1 className="section-title">{title}</h1>

      <div className="process-wrapper">
        <div className="circles">
        {ourProcesses.map((process, index) => (
          <div onClick={() => onCircleClick(index)} key={index} className="circle">
            <div style={index === activeCircleIndex ? {border: "4px solid #0154A3"} : null} className="circle-icon">
              <img src={process.icon.file.url} alt={process.icon.title} />
            </div>
            <div className="circle-text">{process.name}</div>
          </div>
        ))}
  
        </div>

        <div className="process-text">
          <div className="left process-box">
            <div className="process-title">Action</div>
            <div className="process-description">
              {activeCircle.action.action}
            </div>
          </div>
          <div className="right process-box">
            <div className="process-title">Effect</div>
            <div className="process-description">
              {activeCircle.effect.effect}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
