import React from "react"
import "./our-services.scss"
import {
  ANIMATION_DURATION,
  ANIMATION_OFFSET,
  ANIMATION_TYPE,
} from "../../constants"
import InView from "../in-view"

const OurServicesComponent = props => {
  const { cards, header } = props.data
  return (
    <section id="services" className="section container services-cards">
      <h1 className="section-title">{header}</h1>
      <div className="cards">
        {cards.map((card, index) => {
          return (
            <div key={index} className="card-wrapper">
              <InView>
                <div className="card">
                  <div className="image">
                    <img src={card.icon.file.url} alt={card.icon.title}></img>
                  </div>

                  <div className="text">
                    <div className="title">
                      <h4>{card.title}</h4>
                    </div>

                    <div className="desc">
                      <p>{card.description.description}</p>
                    </div>
                  </div>
                </div>
              </InView>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default OurServicesComponent
