import React from "react"
import "./intro-idea.scss"

export const IntroIdeaComponent = props => {
  const { title, innovationCards } = props.data
  return (
    <section className="section container intro-idea">
      <h1 className="section-title">
        Corporate innovation, it all starts with an idea...
      </h1>

      {innovationCards.map((card, index) => (
        <div
          key={index}
          className="idea-section"
          style={
            index % 2 !== 0
              ? { flexDirection: "row-reverse", textAlign: "left" }
              : null
          }
        >
          <div className="left">
            <div className="title">{card.title}</div>

            <div
              className="text"
              style={index % 2 !== 0 ? { marginLeft: 0 } : null}
            >
              {card.text.text}
            </div>
          </div>

          <div className="right">
            <img src={card.icon.file.url} alt={card.icon.title} />
          </div>
        </div>
      ))}
    </section>
  )
}
